
<template>
  <div v-if="isAppReady" class="replyagent_widget_container">

    <!-- enter-active-class="transition duration-200 ease-out" enter-from-class="translate-y-1 opacity-0"
      enter-to-class="translate-y-0 opacity-100" leave-active-class="transition duration-150 ease-in"
      leave-from-class="translate-y-0 opacity-100" leave-to-class="translate-y-1 opacity-0" -->

    <Transition name="bounce">

      <div class="ra_w_container" :class="posClass" v-if="state.showPopUp">
        <div class="ra_w_header">{{ state.widget_data.title }}</div>
        <div class="ra_w_body">
          <div class="ra_w_c">
            <div v-for="channel in state.widget_data.channels" class="ra_channel" :key="channel.type">

              <span v-if="channel.type === 'EMAIL'">
                <a :href="'mailto:' + channel.action"><img :src="state.email_icon" class="ra_icon"></a>
              </span>
              <span v-else-if="channel.type === 'SMS'">
                <a :href="'sms:' + channel.action"><img :src="state.sms_icon" class="ra_icon"></a>
              </span>
              <span v-else-if="channel.type === 'CALL'">
                <a :href="'tel:' + channel.action">
                  <img :src="state.call_icon" class="ra_icon">
                </a>

              </span>
              <span v-else-if="channel.type === 'INSTAGRAM'">
                <a target="_blank" :href="channel.action">
                  <img :src="state.instagram_icon" class="ra_icon">
                </a>
              </span>
              <span v-else-if="channel.type === 'MESSENGER'">
                <a target="_blank" :href="channel.action">
                  <img :src="state.messenger_icon" class="ra_icon">
                </a>
              </span>
              <span v-else-if="channel.type === 'TELEGRAM'">
                <a target="_blank" :href="channel.action">
                  <img :src="state.telegram_icon" class="ra_icon"></a>
              </span>
              <span v-else-if="channel.type === 'WHATSAPP'">
                <a target="_blank" :href="channel.action">
                  <img :src="state.whatsapp_icon" class="ra_icon">
                </a>
              </span>
              <span v-else-if="channel.type === 'ZAPI'">
                <a target="_blank" :href="channel.action">
                  <img :src="state.whatsapp_icon" class="ra_icon">
                </a>
              </span>
            </div>
          </div>

          <p class="ra_footer" v-html="getFooter"></p>
        </div>
      </div>
    </Transition>



    <div class="ra_w_bubble" :class="posClass" :style="styles" @click="viewPopup">
      <img alt="Message Bubble" :src="state.showPopUp ? state.bubble_cancel : state.bubble_icon" class=""
        :class="state.showPopUp ? 'ra_w_bubble_active' : 'ra_w_bubble_closed'">
    </div>


  </div>
</template>

<script>
</script>
<script setup>
import { computed, onMounted, reactive, defineProps } from 'vue';


const props = defineProps({
  slug: {
    type: String,
    default: null
  },
});
const state = reactive({
  showPopUp: false,
  is_loading: true,
  position: "right_bottom",
  widget_data: null,
  bubble_icon: "https://replyagent-staging.s3.amazonaws.com/ra_w/chat.svg",
  bubble_cancel: "https://replyagent-staging.s3.amazonaws.com/ra_w/x.svg",
  instagram_icon: "https://replyagent-staging.s3.amazonaws.com/ra_w/instagram.svg",
  telegram_icon: "https://replyagent-staging.s3.amazonaws.com/ra_w/telegram.svg",
  whatsapp_icon: "https://replyagent-staging.s3.amazonaws.com/ra_w/whatsapp.svg",
  messenger_icon: "https://replyagent-staging.s3.amazonaws.com/ra_w/messenger.svg",
  email_icon: "https://replyagent-staging.s3.amazonaws.com/ra_w/email.svg",
  call_icon: "https://replyagent-staging.s3.amazonaws.com/ra_w/call.svg",
  sms_icon: "https://replyagent-staging.s3.amazonaws.com/ra_w/sms.svg",
});

const isAppReady = computed(() => {
  return !state.is_loading && state.widget_data !== null;
})

const posClass = computed(() => {
  return state.position === "right_bottom" ? "ra_w_right" : "ra_w_left"
})

const styles = computed(() => {
  return state.position === "right_bottom" ? "right:1.5em;" : "left:1.5em;"
})

const viewPopup = () => {
  state.showPopUp = !state.showPopUp;
}

const getFooter = computed(() => {

  return state.widget_data.subtitle.replace(/(https?:\/\/)?(www\.)?[^\s]+\.[^\s]+/g, '<a href="$&" target="_blank" rel="nofollow">$&</a>');
})

onMounted(() => {
  fetchData();
});


async function httpGetAsync(theUrl, callback) {
  var xmlHttp = new XMLHttpRequest();
  xmlHttp.onreadystatechange = function () {
    if (xmlHttp.readyState == 4 && xmlHttp.status == 200 && xmlHttp.responseText !== '') {
      callback(JSON.parse(xmlHttp.responseText));
    }
  }
  xmlHttp.open("GET", theUrl, true); // true for asynchronous 
  xmlHttp.send(null);
}


function fetchData() {

  if (typeof props.slug === "undefined" || props.slug === null || props.slug === "") {
    console.error('Replyagent-Widget Missing slug parameter');
    return false;
  }

  // let el = document.getElementById('ra_w');
  // const widget_slug = el.dataset.slug;

  const widget_slug = props.slug;

  if (typeof widget_slug !== "undefined" && widget_slug !== "") {
    let api_url = process.env.VUE_APP_RA_API_URL || 'http://leadagentglobal.local/widget/';

    console.log('api_url', api_url);
    const http_url = api_url + /widget/ + widget_slug;
    httpGetAsync(http_url, function (response) {
      if (response.widget) {
        state.widget_data = response.widget;
        setVariables(state.widget_data);
      }
      state.is_loading = false;
    });

  }
}

function setVariables(response) {

  state.position = response.position;
  document.documentElement.style.setProperty(
    "--raw-header-bg",
    response.header_bg
  );
  document.documentElement.style.setProperty(
    "--raw-body-bg",
    response.body_bg
  );
  document.documentElement.style.setProperty(
    "--raw-font-family",
    response.font_family
  );
}

</script>
<style>
:root {
  --raw-header-bg: #3D46CD;
  --raw-body-bg: #EDF7FF;
  --raw-font-family: verdana;
  --raw-border-color: #ddd;
  --raw-padding: 1rem;
  --raw-radius: 1rem;
  --raw-header-size: 1.55em;
  --text-white: #ffffff;
}
</style>

